'use strict';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { getUrlParams } from '../../utils/url';
import { Utils } from '../../utils/utils';
import { calculateHoursPerDay } from '../balance/balance.component.js';
let renderPrevisionesCircular = (() => {
  let init = () => {};

  const renderDesviosWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3.select('#mypPrevisionEolicaWidgetView .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    //Pie del gráfico PRECIOS DE LOS DESVÍOS EN TIEMPO REAL
    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#mypPrevisionEolicaWidgetView .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    const graphTime = document.querySelector(
      '#mypPrevisionEolicaWidgetView .mod-date'
    );
    let graphLegend = document.querySelector(
      '#mypPrevisionEolicaWidgetView .esios-listEnergyItems__list'
    );

    if (indicators[0].values.length === 0) {
      graphLegend.innerHTML = '';
      graphTime.innerHTML = '00:00';

      parsedData.map((el, index) => {
        return (graphLegend.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'wind_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }

    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      graphLegend.innerHTML = '';
      parsedData.map((el, index) => {
        graphLegend.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'wind_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect27');
    const widget4 = document.getElementById('mypGraphic27');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic27 > svg').classList.add('hidden');
      document
        .querySelector(
          '#mypPrevisionEolicaWidgetView .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#mypPrevisionEolicaWidgetView .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderFotovoltaicaWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3
        .select('#mypPrevisionFotovoltaicaWidgetView .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours') + '\u00A0', // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },

      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    //Pie del gráfico PRECIOS DE LOS DESVÍOS EN TIEMPO REAL
    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#mypPrevisionFotovoltaicaWidgetView .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    const graphTime = document.querySelector(
      '#mypPrevisionFotovoltaicaWidgetView .mod-date'
    );
    let graphLegend = document.querySelector(
      '#mypPrevisionFotovoltaicaWidgetView .esios-listEnergyItems__list'
    );

    if (indicators[0].values.length === 0) {
      graphLegend.innerHTML = '';
      parsedData.map((el, index) => {
        return (graphLegend.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'photovoltaic_solar_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }

    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      graphLegend.innerHTML = '';
      parsedData.map((el, index) => {
        graphLegend.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'photovoltaic_solar_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect28');
    const widget4 = document.getElementById('mypGraphic28');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic28 > svg').classList.add('hidden');
      document
        .querySelector(
          '#mypPrevisionFotovoltaicaWidgetView .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#mypPrevisionFotovoltaicaWidgetView .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderTermicaWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3.select('#myPrevisionSolarTerWidgetView .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    //Pie del gráfico PRECIOS DE LOS DESVÍOS EN TIEMPO REAL
    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myPrevisionSolarTerWidgetView .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    const graphTime = document.querySelector(
      '#myPrevisionSolarTerWidgetView .mod-date'
    );
    let graphLegend = document.querySelector(
      '#myPrevisionSolarTerWidgetView .esios-listEnergyItems__list'
    );

    if (indicators[0].values.length === 0) {
      graphLegend.innerHTML = '';
      parsedData.map((el, index) => {
        return (graphLegend.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'solar_thermal_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }

    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      graphLegend.innerHTML = '';
      parsedData.map((el, index) => {
        graphLegend.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'solar_thermal_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect29');
    const widget4 = document.getElementById('mypGraphic29');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic29 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionSolarTerWidgetView .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionSolarTerWidgetView .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderFacturationWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'linear',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3
        .select('#mypPrevisionSemanalDemandaWidgetView .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail26',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 5, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#mypPrevisionSemanalDemandaWidgetView .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#mypPrevisionSemanalDemandaWidgetView .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#mypPrevisionSemanalDemandaWidgetView .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'weekly_demand_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';" style="color: #444444;">-</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'weekly_demand_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect26');
    const widget4 = document.getElementById('mypGraphic26');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic26 > svg').classList.add('hidden');
      document
        .querySelector(
          '#mypPrevisionSemanalDemandaWidgetView .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#mypPrevisionSemanalDemandaWidgetView .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisionDiariaD1DemandaWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3.select('#myPrevisionDiariaD1Wiew .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail30',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myPrevisionDiariaD1Wiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myPrevisionDiariaD1Wiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionDiariaD1Wiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'daily_forecast_D+1_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'daily_forecast_D+1_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect30');
    const widget4 = document.getElementById('mypGraphic30');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic30 > svg').classList.add('hidden');
      document
        .querySelector('#myPrevisionDiariaD1Wiew .esios-listEnergyItems__list')
        .classList.add('no-data');
    } else {
      document
        .querySelector('#myPrevisionDiariaD1Wiew .esios-listEnergyItems__list')
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };
  const renderPrevisionDemandaAnualWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3.select('#myPrevisionDemandaAnualWiew .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail12',
        enabled: true,
        parseStep: function (date) {
          // Asegurarse de que se agrupe por año
          date = moment(date).startOf('year').toDate();
          return date;
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData.date).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.years, 1], // Ticks cada año

        bottom: {
          label: i18next.t('years'), // Etiqueta para los años
          tickFormat: function (date) {
            // Obtener el año
            const year = date.getFullYear();

            // Devolver el año como etiqueta del tick
            return year.toString();
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myPrevisionDemandaAnualWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myPrevisionDemandaAnualWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionDemandaAnualWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'annual_demand_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'annual_demand_forecast'
                            )} ( ${moment(parsedData[0].x).format(
          'DD/MM/YYYY'
        )} )</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect12');
    const widget4 = document.getElementById('mypGraphic12');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic12 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionDemandaAnualWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionDemandaAnualWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisionDiariaD1EolicaWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3.select('#myPrevisionDiariaD1EolicaWiew .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail31',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myPrevisionDiariaD1EolicaWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myPrevisionDiariaD1EolicaWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionDiariaD1EolicaWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'daily-forecast-d1-wind-plus-photovoltaic'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'daily-forecast-d1-wind-plus-photovoltaic'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect31');
    const widget4 = document.getElementById('mypGraphic31');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic31 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionDiariaD1EolicaWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionDiariaD1EolicaWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisionIntraDiariaD1DemandaWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3
        .select('#myPrevisionIntraDiariaH3DemandaWiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail32',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myPrevisionIntraDiariaH3DemandaWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myPrevisionIntraDiariaH3DemandaWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionIntraDiariaH3DemandaWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'intraday_forecast_H+3_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'intraday_forecast_H+3_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect32');
    const widget4 = document.getElementById('mypGraphic32');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic32 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionIntraDiariaH3DemandaWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionIntraDiariaH3DemandaWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisionIntraDiariaD1DemandaFotovoltaicaWidget = async (
    data
  ) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3
        .select('#myPrevisionIntraDiariaH3DemandaFotovolWiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail33',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date).startOf('hour').toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myPrevisionIntraDiariaH3DemandaFotovolWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myPrevisionIntraDiariaH3DemandaFotovolWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionIntraDiariaH3DemandaFotovolWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'intraday_forecast_H+3_more_photovoltaic_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'intraday_forecast_H+3_more_photovoltaic_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect33');
    const widget4 = document.getElementById('mypGraphic33');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic33 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionIntraDiariaH3DemandaFotovolWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionIntraDiariaH3DemandaFotovolWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderDemandaPrevistaPeninsularWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'linear',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3.select('#myDemandaPrevistaPeninsularWiew .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail13',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 5, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myDemandaPrevistaPeninsularWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myDemandaPrevistaPeninsularWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myDemandaPrevistaPeninsularWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'peninsular_forecast_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'peninsular_forecast_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect13');
    const widget4 = document.getElementById('mypGraphic13');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic13 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myDemandaPrevistaPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myDemandaPrevistaPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderDemandaPrevistaSNPWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'linear',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3.select('#myDemandaPrevistaSNPWiew .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail14',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 5, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myDemandaPrevistaSNPWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myDemandaPrevistaSNPWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myDemandaPrevistaSNPWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'snp_forecast_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'snp_forecast_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect14');
    const widget4 = document.getElementById('mypGraphic14');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic14 > svg').classList.add('hidden');
      document
        .querySelector('#myDemandaPrevistaSNPWiew .esios-listEnergyItems__list')
        .classList.add('no-data');
    } else {
      document
        .querySelector('#myDemandaPrevistaSNPWiew .esios-listEnergyItems__list')
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderDemandaDiariaElectricaPeninsularWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3
        .select('#myDemandaDiariaElectricaPeninsularWiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail15',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myDemandaDiariaElectricaPeninsularWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myDemandaDiariaElectricaPeninsularWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myDemandaDiariaElectricaPeninsularWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;" ">${i18next.t(
                              'daily_forecast_of_peninsular_electricity_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'daily_forecast_of_peninsular_electricity_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect15');
    const widget4 = document.getElementById('mypGraphic15');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic15 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myDemandaDiariaElectricaPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myDemandaDiariaElectricaPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderDemandaMensualElectricaPeninsularWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3
        .select('#myDemandaMensualElectricaPeninsularWiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail16',
        enabled: true,
        parseStep: function (date) {
          // Asegurarse de que se agrupe por mes
          date = moment(date).startOf('month').toDate();
          return date;
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.months, 1], // Ticks cada mes

        bottom: {
          label: i18next.t('months'), // Etiqueta para los meses
          tickFormat: function (date) {
            const month = date.toLocaleString('default', { month: 'short' }); // Obtener el nombre del mes abreviado
            return `${month.charAt(0).toUpperCase() + month.slice(1)}`;
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);

    let graphTime = document.querySelector(
      '#myDemandaMensualElectricaPeninsularWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myDemandaMensualElectricaPeninsularWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'monthly_forecast_of_peninsular_electricity_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'monthly_forecast_of_peninsular_electricity_demand'
                            )} (${moment(parsedData[0].x).format(
          'DD/MM/YYYY'
        )})</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect16');
    const widget4 = document.getElementById('mypGraphic16');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic16 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myDemandaMensualElectricaPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myDemandaMensualElectricaPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderDemandaSemanalElectricaPeninsularWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3
        .select('#myDemandaSemanalElectricaPeninsularWiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail17',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myDemandaSemanalElectricaPeninsularWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myDemandaSemanalElectricaPeninsularWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myDemandaSemanalElectricaPeninsularWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'weekly_forecast_of_peninsular_electricity_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'weekly_forecast_of_peninsular_electricity_demand'
                            )} </span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect17');
    const widget4 = document.getElementById('mypGraphic17');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic17 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myDemandaSemanalElectricaPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myDemandaSemanalElectricaPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisionMaximaAnualPeninsularWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3
        .select('#myPrevisionMaximaAnualPeninsularWiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail18',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData.date).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myPrevisionMaximaAnualPeninsularWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myPrevisionMaximaAnualPeninsularWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionMaximaAnualPeninsularWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'wind_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'wind_forecast'
                            )} </span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect18');
    const widget4 = document.getElementById('mypGraphic18');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic18 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionMaximaAnualPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionMaximaAnualPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderGeneracionPrevistaSolarWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });
    var options = {
      target: d3.select('#myGeneracionPrevistaSolarWiew .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail19',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myGeneracionPrevistaSolarWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myGeneracionPrevistaSolarWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myGeneracionPrevistaSolarWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'solar_planned_generación'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'solar_planned_generación'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect19');
    const widget4 = document.getElementById('mypGraphic19');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic19 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myGeneracionPrevistaSolarWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myGeneracionPrevistaSolarWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisionMaximaAnualPeninsular2Widget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3
        .select('#myPrevisionMaximaAnualPeninsular2Wiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail20',
        enabled: true,
        parseStep: function (date) {
          date = moment(date).startOf('week').toDate();
          return date;
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData.date).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.months, 1], // Cambiar de d3.time.days a d3.time.months

        bottom: {
          label: i18next.t('months'), // Cambiar 'days' por 'months'
          tickFormat: function (date) {
            const month = date.toLocaleString('default', { month: 'short' }); // Obtener el nombre del mes abreviado
            return `${month.charAt(0).toUpperCase() + month.slice(1)}`;
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MW',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);

    let graphTime = document.querySelector(
      '#myPrevisionMaximaAnualPeninsular2Wiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionMaximaAnualPeninsular2Wiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'maximum_annual_forecast_of_peninsular_electricity_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'maximum_annual_forecast_of_peninsular_electricity_demand'
                            )} ( ${moment(parsedData[0].x).format(
          'DD/MM/YYYY'
        )} )</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MW</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect20');
    const widget4 = document.getElementById('mypGraphic20');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic20 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionMaximaAnualPeninsular2Wiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionMaximaAnualPeninsular2Wiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisionMinimaAnualPeninsularWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3
        .select('#myPrevisionMinimaAnualPeninsularWiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail21',
        enabled: true,
        parseStep: function (date) {
          return moment(date).startOf('week').toDate();
        },
        initXValue: function () {
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.months, 1], // Cambiar de d3.time.days a d3.time.months

        bottom: {
          label: i18next.t('months'), // Cambiar 'days' por 'months'
          tickFormat: function (date) {
            const month = date.toLocaleString('default', { month: 'short' }); // Obtener el nombre del mes abreviado
            return `${month.charAt(0).toUpperCase() + month.slice(1)}`;
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);

    let graphTime = document.querySelector(
      '#myPrevisionMinimaAnualPeninsularWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionMinimaAnualPeninsularWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'minimum_annual_forecast_of_peninsular_electricity_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'minimum_annual_forecast_of_peninsular_electricity_demand'
                            )} ( ${moment(parsedData[0].x).format(
          'DD/MM/YYYY'
        )} )</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect21');
    const widget4 = document.getElementById('mypGraphic21');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic21 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionMinimaAnualPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionMinimaAnualPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisionMaximaMensualPeninsularWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3
        .select('#myPrevisionMaximaMensualPeninsularWiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail22',
        enabled: true,
        parseStep: function (date) {
          date = moment(date).startOf('week').toDate(); // Asegurarse de que se agrupe por día
          return date;
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.day, 4], // Cambiar a cada 4 días

        bottom: {
          label: i18next.t('days'),
          tickFormat: function (date) {
            date.setDate(date.getDate() + 1);
            const month = date.toLocaleString('default', { month: 'short' }); // Obtener el nombre del mes abreviado
            const day = date.getDate(); // Obtener el día
            return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)}`;
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);

    let graphTime = document.querySelector(
      '#myPrevisionMaximaMensualPeninsularWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionMaximaMensualPeninsularWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'maximum_monthly_forecast_of_peninsular_electricity_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'maximum_monthly_forecast_of_peninsular_electricity_demand'
                            )} (${moment(parsedData[0].x).format(
          'DD/MM/YYYY'
        )})</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect22');
    const widget4 = document.getElementById('mypGraphic22');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic22 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionMaximaMensualPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionMaximaMensualPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisionMinimaMensualPeninsularWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3
        .select('#myPrevisionMinimaMensualPeninsularWiew .chari-chart')
        .node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail23',
        enabled: true,
        parseStep: function (date) {
          date = moment(date).startOf('week').toDate(); // Asegurarse de que se agrupe por día
          return date;
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.day, 4], // Cambiar a cada 4 días
        bottom: {
          label: i18next.t('days'),
          tickFormat: function (date) {
            date.setDate(date.getDate() + 1);
            const month = date.toLocaleString('default', { month: 'short' }); // Obtener el nombre del mes abreviado
            const day = date.getDate(); // Obtener el día
            return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)}`;
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);

    let graphTime = document.querySelector(
      '#myPrevisionMinimaMensualPeninsularWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisionMinimaMensualPeninsularWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'minimum_monthly_forecast_of_peninsular_electricity_demand'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'minimum_monthly_forecast_of_peninsular_electricity_demand'
                            )} (${moment(parsedData[0].x).format(
          'DD/MM/YYYY'
        )})</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect23');
    const widget4 = document.getElementById('mypGraphic23');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic23 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisionMinimaMensualPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisionMinimaMensualPeninsularWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderMargenGeneracionWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3.select('#myMargenGeneracionWiew .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail24',
        enabled: true,
        parseStep: function (date) {
          // Asegurarse de que se agrupe por año
          date = moment(date).startOf('year').toDate();
          return date;
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1];
          return moment(lastData.date).toDate();
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.years, 1], // Ticks cada año

        bottom: {
          label: i18next.t('years'), // Etiqueta para los años
          tickFormat: function (date) {
            // Obtener el año
            const year = date.getFullYear();

            // Devolver el año como etiqueta del tick
            return year.toString();
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);

    let graphTime = document.querySelector('#myMargenGeneracionWiew .mod-date');
    let facturacionLegends = document.querySelector(
      '#myMargenGeneracionWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'available_generation_margin_Maximum_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'available_generation_margin_Maximum_forecast'
                            )} (${moment(parsedData[0].x).format(
          'DD/MM/YYYY'
        )})</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect24');
    const widget4 = document.getElementById('mypGraphic24');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic24 > svg').classList.add('hidden');
      document
        .querySelector('#myMargenGeneracionWiew .esios-listEnergyItems__list')
        .classList.add('no-data');
    } else {
      document
        .querySelector('#myMargenGeneracionWiew .esios-listEnergyItems__list')
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  const renderPrevisonDemandaResidualWidget = async (data) => {
    const indicators = [];
    indicators.push(data);
    const graphColors = ['#00A1DA', '#DF4A32'];
    const lastDate = [];
    const timeID = indicators[0].time;
    let step = Utils.getTimeValue(timeID) || 10;
    const parsedData = indicators.map((el, index) => {
      if (index === 0) {
        el.values.map((date) => {
          if (date.y !== null) {
            lastDate.push(moment(date.x).tz('Europe/Madrid').format());
          }
        });
      }
      return {
        id: el.id,
        type: 'line',
        name: el.short_name,
        values: el.values,
        dots: false,
        interpolation: 'step-after',
        color: graphColors[index],
      };
    });

    var options = {
      target: d3.select('#myPrevisonDemandaResidualWiew .chari-chart').node(),
      margin: '40 15 25 45',
      trail: {
        id: 'trail25',
        enabled: true,
        parseStep: function (date) {
          // Agrupar por hora
          return moment(date)
            .startOf('minute')
            .subtract(moment(date).minute() % 15, 'minutes')
            .toDate();
        },
        initXValue: function () {
          // Obtener el último dato disponible
          var lastData = lastDate[lastDate.length - 1]; // Reemplaza 'yourDataArray' con tu arreglo de datos
          return moment(lastData.date).toDate(); // Reemplaza 'date' con la propiedad que contiene la fecha en tu objeto de datos
        },
      },
      xaxis: {
        scale: 'time',
        fit: true,
        ticks: [d3.time.hours, 3], // Cambiar a agrupación por horas

        bottom: {
          label: i18next.t('hours'), // Cambiar la etiqueta a "Horas"
          tickFormat: function (date) {
            return moment(date).format('HH:mm'); // Formato de hora
          },
        },
      },
      yaxis: {
        fit: true,
        textMarginTop: -6,
        ticks: [5],
        left: {
          label: 'MWh',
          tickFormat: function (y, i) {
            if (y >= 1000000) {
              var millionFormat = (y / 1000000).toFixed(1) + 'M';
              return millionFormat.replace('.0', ''); // Eliminar el ".0" si no hay decimales
            } else {
              return Utils.toNumber(y, 0, 'comma');
            }
          },
        },
      },
    };

    var chart = new Charicharts.Chart(options, parsedData);
    d3.select(
      '#myPrevisonDemandaResidualWiew .chari-chart .xaxis.bottom g.tick'
    ).attr('transform', 'translate(4,0)');

    let graphTime = document.querySelector(
      '#myPrevisonDemandaResidualWiew .mod-date'
    );
    let facturacionLegends = document.querySelector(
      '#myPrevisonDemandaResidualWiew .esios-listEnergyItems__list .row'
    );
    if (indicators[0].values.length === 0) {
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        return (facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'residual_demand_forecast'
                            )}</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">-</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `);
      });
    }
    chart.on('Trail/moved', function (parsedData, date) {
      graphTime.innerHTML =
        parsedData[0].y === null
          ? '00:00'
          : moment(date).tz('Europe/Madrid').format('HH:mm');
      facturacionLegends.innerHTML = '';
      parsedData.map((el, index) => {
        facturacionLegends.innerHTML += `
          <div class="col-xs-12">
                        <li class="esios-listEnergyItems__item-with-value" id="legend-686">
                          <div class="esios-listEnergyItems__label">
                            <span class="esios-listEnergyItems__decorator" style="background-color: ${
                              graphColors[index]
                            };"></span>
                            <span class="label" style="color: #717171; text-transform: uppercase; font-size: 0.875rem; font-family: 'Proxima-Nova', Arial, sans-serif; font-weight: 600 !important;">${i18next.t(
                              'peninsular_forecast_demand'
                            )} (${moment(parsedData[0].x).format(
          'DD/MM/YYYY'
        )})</span>
                            <div class="value is-number" style="margin: 0.7vh 0 0 1rem !important;">
                              <span class="caption" style="color: #444444; font-size: 1.4375rem; letter-spacing: -0.145rem; font-family: 'Cousine';">${
                                parsedData[0].y === null
                                  ? '-'
                                  : Utils.toNumber(el.y, 1, 'comma')
                              }</span><span class="unit" style="margin-left: 5px;">MWh</span>
                            </div>
                          </div>
                        </li>
                      </div>
          `;
      });
    });

    const dataLength = indicators[0].values.length;
    const rectNoData4 = document.getElementById('mypNoDataRect25');
    const widget4 = document.getElementById('mypGraphic25');
    if (dataLength === 0) {
      widget4.classList.add('no-data-graphic');
      rectNoData4.classList.remove('hidden');
      document.querySelector('#mypGraphic25 > svg').classList.add('hidden');
      document
        .querySelector(
          '#myPrevisonDemandaResidualWiew .esios-listEnergyItems__list'
        )
        .classList.add('no-data');
    } else {
      document
        .querySelector(
          '#myPrevisonDemandaResidualWiew .esios-listEnergyItems__list'
        )
        .classList.remove('no-data');
      widget4.classList.remove('no-data-graphic');
      rectNoData4.classList.add('hidden');
    }
  };

  return {
    init: init,

    renderDesviosWidget: (data) => renderDesviosWidget(data),
    renderFacturationWidget: (data) => renderFacturationWidget(data),
    renderFotovoltaicaWidget: (data) => renderFotovoltaicaWidget(data),
    renderTermicaWidget: (data) => renderTermicaWidget(data),
    renderPrevisionDiariaD1DemandaWidget: (data) =>
      renderPrevisionDiariaD1DemandaWidget(data),
    renderPrevisionDiariaD1EolicaWidget: (data) =>
      renderPrevisionDiariaD1EolicaWidget(data),
    renderPrevisionIntraDiariaD1DemandaWidget: (data) =>
      renderPrevisionIntraDiariaD1DemandaWidget(data),
    renderPrevisionDemandaAnualWidget: (data) =>
      renderPrevisionDemandaAnualWidget(data),
    renderPrevisionIntraDiariaD1DemandaFotovoltaicaWidget: (data) =>
      renderPrevisionIntraDiariaD1DemandaFotovoltaicaWidget(data),
    renderDemandaPrevistaPeninsularWidget: (data) =>
      renderDemandaPrevistaPeninsularWidget(data),
    renderDemandaPrevistaSNPWidget: (data) =>
      renderDemandaPrevistaSNPWidget(data),
    renderDemandaDiariaElectricaPeninsularWidget: (data) =>
      renderDemandaDiariaElectricaPeninsularWidget(data),
    renderDemandaMensualElectricaPeninsularWidget: (data) =>
      renderDemandaMensualElectricaPeninsularWidget(data),
    renderDemandaSemanalElectricaPeninsularWidget: (data) =>
      renderDemandaSemanalElectricaPeninsularWidget(data),
    renderPrevisionMaximaAnualPeninsularWidget: (data) =>
      renderPrevisionMaximaAnualPeninsularWidget(data),
    renderGeneracionPrevistaSolarWidget: (data) =>
      renderGeneracionPrevistaSolarWidget(data),
    renderPrevisionMaximaAnualPeninsular2Widget: (data) =>
      renderPrevisionMaximaAnualPeninsular2Widget(data),
    renderPrevisionMinimaAnualPeninsularWidget: (data) =>
      renderPrevisionMinimaAnualPeninsularWidget(data),
    renderPrevisionMaximaMensualPeninsularWidget: (data) =>
      renderPrevisionMaximaMensualPeninsularWidget(data),
    renderPrevisionMinimaMensualPeninsularWidget: (data) =>
      renderPrevisionMinimaMensualPeninsularWidget(data),
    renderMargenGeneracionWidget: (data) => renderMargenGeneracionWidget(data),
    renderPrevisonDemandaResidualWidget: (data) =>
      renderPrevisonDemandaResidualWidget(data),
  };
})();

export { renderPrevisionesCircular };

export const processValues = (rangeNumber, arreglo, date, negative = false) => {
  let totalHours = Utils.getHoursOfDay(date, 'DD-MM-YYYY');
  if (totalHours == arreglo.length) {
    let newArr = [];

    arreglo.forEach((value) => {
      let newY = value.y;
      if (negative) {
        newY = value.y == 0 ? 0 : value.y == null ? null : value.y * -1;
      }
      newArr = [...newArr, { x: value.x, y: newY, z: 'prueba' }];
    });
    return newArr;
  } else {
    let arr = [];
    let dateSplit = date.split('-');
    for (let i = 0; i < rangeNumber; i++) {
      let x = new Date(
        `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}T${
          i < 10 ? '0' + i : i
        }:00:00`
      );

      arr.push({
        x,
        y: null,
      });
    }
    arreglo.filter((el) => {
      let index = parseInt(moment(el.x).tz('Europe/Madrid').format('HH'));
      let yval = 0;
      if (negative) {
        yval = el.y === 0 ? 0 : el.y === null ? null : el.y * -1;
      }

      arr[index] = {
        x: new Date(el.x),
        y: negative ? yval : el.y,
      };
    });
    arr.map((el) => {
      if (el.y === null) {
        let index = parseInt(new Date(el.x).getHours());

        return (arr[index] = {
          ...el,
          x: el.x,
        });
      }
    });
    return arr;
  }
};
