'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import { API_URL } from '../../environment.json';
import { componentDocumentList } from '../../shared/components/document-list/document-list.component.js';
import { componentLayoutExtended } from '../../shared/components/layoutExtended/layoutExtended.component.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentRapidAccess } from '../../shared/components/rapid-access/rapid-access.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component';
import { servicesnoPeninsulares } from './noPeninsulares.services';
import { templatenoPeninsulares } from './noPeninsulares.template';
import { dateSetTime } from '../../utils/dateFormat.js';
import { api } from '../../utils/api-interceptor.js';

let componentnoPeninsulares = (function () {
  let context = {};
  let filterForm = {
    ambit: '',
    category: '',
    process: '',
    action: '',
    query: '',
  };
  let renderSuccess = false;
  let canaryNumbers;
  let melillaNumbers;
  let balearNumbers;
  let ceutaNumbers;
  let snpMoreData;
  let modalText;
  let snpData;
  let titles;

  let setInfoArr = [
    {
      id: 1,
      array: balearNumbers,
      slug: 'baleares',
    },
    {
      id: 2,
      array: canaryNumbers,
      slug: 'canarias',
    },
    {
      id: 3,
      array: ceutaNumbers,
      slug: 'ceuta',
    },
    {
      id: 4,
      array: melillaNumbers,
      slug: 'melilla',
    },
  ];

  let obToolbar = [];

  let documents = [
    {
      title: 'Informe incidente Tenerife 15 julio 2020',
      link: '/es/documentacion/informe-incidente-tenerife-15-julio-2020',
    },
    {
      title: 'Informe Incidente Tenerife 29 septiembre 2019',
      link: '/es/documentacion/informe-incidente-tenerife-29-septiembre-2019',
    },
    {
      title:
        'Intercambio de Información con el OS Vol.4 Extrapeninsulares v1.0.13',
      link: '/es/documentacion/intercambio-de-informacion-con-el-os-extrapeninsulares',
    },
    {
      title:
        'P.O. 2.3: Programación del intercambio de energía por el enlace eléctrico entre el sistema eléctrico balear y el sistema eléctrico peninsular',
      link: '/es/documentacion/p-o-2-3-programacion-del-intercambio-de-energia-por-el-enlace-electrico-entre-el-sistema-electrico-balear-y-el-sistema-electrico-peninsular',
    },
  ];

  let preRenderComponent = async () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('non_peninsular_systems')} | ${i18n.t(
      'page_subtitle'
    )}`;
    obToolbar = [
      {
        id: 12,
        slug: i18n.t('routes.baleares'),
        info: true,
        share: true,
        favorite: true,
        analysis: true,
        favId: 12,
        textModal: '',
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/573?compare_indicators=10244,572&start_date=${dateSetTime()}&geoids=8743`,
      },
      {
        id: 13,
        slug: i18n.t('routes.canarias'),
        info: true,
        share: true,
        favorite: true,
        analysis: true,
        favId: 13,
        textModal: '',
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/573?compare_indicators=10243&start_date=${dateSetTime()}&geoids=8742`,
      },
      {
        id: 14,
        slug: i18n.t('routes.ceuta'),
        info: true,
        share: true,
        favorite: true,
        analysis: true,
        favId: 14,
        textModal: '',
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/573?compare_indicators=1347&start_date=${dateSetTime()}&geoids=8744`,
      },
      {
        id: 15,
        slug: i18n.t('routes.melilla'),
        info: true,
        share: true,
        favorite: true,
        analysis: true,
        favId: 15,
        textModal: '',
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/573?compare_indicators=1348&start_date=${dateSetTime()}&geoids=8745`,
      },
    ];
    context.name = state.exampleReducer.name;
  };

  let setInfoModalArr = (array) => {
    array.forEach((arr, index) => {
      setInfoArr[index].array = arr;
    });
  };

  let setDateTimeForGeoID = (id, data) => {
    let dateInput = document.querySelector(`.esios-noPeninsulares__date.${id}`);
    if (data) {
      dateInput.innerText =
        moment(data[0]?.datetime)
          .tz('Europe/Madrid')
          .format('DD / MM / YYYY') || '-- / -- / --';
    }
  };

  let setInfoModal = (id, data, modalData, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share') {
      obToolbar[id - 1].textModal = `<div class="esios-noPeninsulares__title">
      <h1>${i18n.t(modalData.slug).toUpperCase()}</h1>
    </div>
    ${modalData.modalText}`;
    }
    obToolbar[id - 1].widgetData = {
      slug: modalData.slug,
      indicators: data,
    };
    obToolbar[id - 1].shareModal = html;
  };

  let renderComponent = async () => {
    const state = store.getState();
    let buttons = [
      {
        label: 'see_more_doc',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}?scope=${i18n.t('routes.non_peninsular_systems')}`,
      },
      {
        label: 'downloads_files',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.downloads')}`,
      },
    ];

    try {
      //Rapid access
      const RapidAccessData = [
        {
          id: '#baleares',
          title: 'Baleares',
        },
        {
          id: '#canarias',
          title: 'Canarias',
        },
        {
          id: '#ceuta',
          title: 'Ceuta',
        },
        {
          id: '#melilla',
          title: 'Melilla',
        },
        {
          id: '#currentNews',
          title: 'more',
        },
      ];
      componentRapidAccess.render(RapidAccessData);

      // Get currently date
      const currentlyTime = Date.now();
      const currentlyDay = new Date(currentlyTime);
      const toDayDate = currentlyDay.toLocaleDateString();
      let formatDate = toDayDate.split('/');
      const [d, m, y] = formatDate;

      templatenoPeninsulares(
        snpData,
        documents,
        snpMoreData,
        titles,
        buttons,
        balearNumbers,
        canaryNumbers,
        melillaNumbers,
        ceutaNumbers
      );

      inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');

      inputCalendar.setAttribute('disabled', 'true');
      divCalendar.classList.add('disabled');

      document
        .querySelector('.esios-layout__wrap')
        .classList.add('esios-layout__wrap--no-peninsulares');

      let dateCurrentTime = document.getElementById('datepicker').value;
      let datetimeFormated = moment(dateCurrentTime, 'DD / MM / YYYY')
        .tz('Europe/Madrid')
        .format('YYYY-MM-DDTHH:mm:ss');

      modalText = await servicesnoPeninsulares.modalData(
        state.exampleReducer.currentLang,
        15,
        datetimeFormated
      );
      snpMoreData = await servicesnoPeninsulares.moreData(
        state.exampleReducer.currentLang,
        16
      );

      // Nuevo objeto 2165 a agregar
      let idnewarchive = 2165;
      let newData = {
        alias: 'VertidosTNP',
        display_options: { min: 1, max: 1 },
        id: idnewarchive,
        main: true,
        name: 'VertidosTNP',
        serie_type: 'linear',
        short_name: 'VertidosTNP',
        url:
          API_URL +
          '/documents/' +
          idnewarchive +
          '/download?locale=' +
          state.exampleReducer.currentLang,
      };
      // Agregar el nuevo objeto a la lista
      snpMoreData.push(newData);
      snpMoreData.map((archive) => {
        if (archive.id === 2165) {
          return (archive.url =
            API_URL +
            '/documents/' +
            archive.id +
            '/download?locale=' +
            state.exampleReducer.currentLang);
        } else {
          return (archive.url =
            API_URL +
            '/archives/' +
            archive.id +
            '/download?locale=' +
            state.exampleReducer.currentLang);
        }
      });
      //Get data from API
      snpData = await servicesnoPeninsulares.init(
        state.exampleReducer.currentLang
      );
      titles = await servicesnoPeninsulares.getTitles(
        state.exampleReducer.currentLang,
        12,
        datetimeFormated
      );
      balearNumbers = await servicesnoPeninsulares.dataNumber(
        state.exampleReducer.currentLang,
        12,
        datetimeFormated
      );

      canaryNumbers = await servicesnoPeninsulares.dataNumber(
        state.exampleReducer.currentLang,
        13,
        datetimeFormated
      );
      ceutaNumbers = await servicesnoPeninsulares.dataNumber(
        state.exampleReducer.currentLang,
        14,
        datetimeFormated
      );
      melillaNumbers = await servicesnoPeninsulares.dataNumber(
        state.exampleReducer.currentLang,
        15,
        datetimeFormated
      );

      let shareData = window.location.search;

      setInfoModalArr([
        balearNumbers,
        canaryNumbers,
        ceutaNumbers,
        melillaNumbers,
      ]);

      setInfoArr.forEach((el) => {
        setInfoModal(
          el.id,
          el.array,
          {
            slug: el.slug,
            modalText,
          },
          templateShareModal(el.slug, shareData)
        );
        setDateTimeForGeoID(el.slug, el.array);
      });

      templatenoPeninsulares(
        snpData,
        documents,
        snpMoreData,
        titles,
        buttons,
        balearNumbers,
        canaryNumbers,
        melillaNumbers,
        ceutaNumbers
      );

      inputCalendar.removeAttribute('disabled');
      divCalendar.classList.remove('disabled');

      const renderToolbar = () => {
        //Render toolbar
        obToolbar.map((element) => {
          componentToolbar.render(element, '.esios-toolbar' + element.id);
        });
      };
      renderToolbar();
      //Datepicker
      let inputCalendar = document.querySelector('#datepicker');
      const time = Date.now();
      const today = new Date(time);
      const dateOfToday = today.toLocaleDateString();
      const formatDatePicker =
        today.getDate() +
        ' / ' +
        today.getMonth() +
        1 +
        ' / ' +
        today.getFullYear();

      inputCalendar.addEventListener('change', async function (e) {
        let divCalendar = document.querySelector('.is-day-selectable');
        inputCalendar.setAttribute('disabled', 'true');
        divCalendar.classList.add('disabled');

        if (inputCalendar.value !== dateOfToday) {
          let setFormatDate = inputCalendar.value.split('/');
          const [day, month, year] = setFormatDate;

          let dateCurrentTime = document.getElementById('datepicker').value;
          let datetimeFormated = moment(dateCurrentTime, 'DD / MM / YYYY')
            .tz('Europe/Madrid')
            .format();
          balearNumbers = undefined;
          canaryNumbers = undefined;
          ceutaNumbers = undefined;
          melillaNumbers = undefined;
          templatenoPeninsulares(
            snpData,
            documents,
            snpMoreData,
            titles,
            buttons,
            balearNumbers,
            canaryNumbers,
            melillaNumbers,
            ceutaNumbers
          );

          modalText = await servicesnoPeninsulares.modalData(
            state.exampleReducer.currentLang,
            15,
            datetimeFormated
          );
          balearNumbers = await servicesnoPeninsulares.dataNumber(
            state.exampleReducer.currentLang,
            12,
            datetimeFormated
          );
          canaryNumbers = await servicesnoPeninsulares.dataNumber(
            state.exampleReducer.currentLang,
            13,
            datetimeFormated
          );
          ceutaNumbers = await servicesnoPeninsulares.dataNumber(
            state.exampleReducer.currentLang,
            14,
            datetimeFormated
          );
          melillaNumbers = await servicesnoPeninsulares.dataNumber(
            state.exampleReducer.currentLang,
            15,
            datetimeFormated
          );
          templatenoPeninsulares(
            snpData,
            documents,
            snpMoreData,
            titles,
            buttons,
            balearNumbers,
            canaryNumbers,
            melillaNumbers,
            ceutaNumbers
          );

          inputCalendar.removeAttribute('disabled');
          divCalendar.classList.remove('disabled');

          setInfoModalArr([
            balearNumbers,
            canaryNumbers,
            ceutaNumbers,
            melillaNumbers,
          ]);

          setInfoArr.forEach((el) => {
            setInfoModal(
              el.id,
              el.array,
              {
                slug: el.slug,
                modalText,
              },
              templateShareModal(el.slug, shareData),
              'share'
            );
            setDateTimeForGeoID(el.slug, el.array);
          });

          renderToolbar();
        }
      });

      // DocumentList
      let newDLArray = {
        renderAgain: (form) => renderComponentDocumentList(form),
        onlyRefresh: () => renderComponentListAgain(),
      };
      componentDocumentList.render(newDLArray, '#document-list-myp');
      renderSuccess = true;
    } catch (error) {
      console.error(error);
      renderSuccess = false;
    }

    let calendar = document.querySelector(
      '.esios-layout__breadcrumbs__calendar-container'
    );
    calendar.classList.add(
      'esios-layout__breadcrumbs__calendar-container--no-peninsulares'
    );
    document
      .querySelector('.esios-layout__breadcrumbs__wrapper')
      .classList.add('esios-layout__breadcrumbs__wrapper--no-peninsulares');
    document
      .querySelector('.esios-layout__breadcrumbs')
      .classList.add('esios-layout__breadcrumbs--no-peninsulares');
    document
      .querySelector('.t-max-height')
      .classList.add('t-max-height--no-peninsulares');
    document.querySelector(
      '.esios-layout__breadcrumbs__wrapper'
    ).style.display = 'block';
    document
      .querySelector('.esios-boxdoc__footer')
      .classList.add('esios-boxdoc__footer--no-peninsulares');
    document
      .querySelector('#esios-main-page')
      .classList.add('esios-main-page--no-peninsulares');

    document
      .querySelector('.esios-market-prices__form')
      .classList.add('esios-market-prices__form--no-peninsulares');
    document
      .querySelector('.esios-boxdoc__list')
      .classList.add('esios-boxdoc__list--no-peninsulares');
    document
      .querySelector('.row.is-strech')
      .classList.add('row.is-strech--no-peninsulares');
    let dateCurrentTime = document.getElementById('datepicker').value;
    if (!dateCurrentTime.includes(' ')) {
      let datetimeFormated = moment(dateCurrentTime, 'DD/MM/YYYY')
        .tz('Europe/Madrid')
        .format('DD / MM / YYYY');
      document.getElementById('datepicker').value = datetimeFormated;
    }
    let blockMore = document.querySelector('#currentNews');
  };

  const fixImageMaps = () => {
    const imgs = document.querySelectorAll('img[usemap]');
    imgs.forEach((img) => {
      if (!img.naturalHeight) {
        return;
      }
      const h = img.height / img.naturalHeight;
      const w = img.width / img.naturalWidth;
      const map = document.getElementsByName(img.useMap.slice(1))[0];
      if (!map) {
        return;
      }
      for (let i = 0; i < map.children.length; i++) {
        const area = map.children[i];
        const coords = area.coords;
        if (!area.originalCoords) {
          area.originalCoords = coords;
        } else {
          coords = area.originalCoords;
        }
        const split = coords.split(',');
        let fixed = '';
        split.forEach((coord, n) => {
          if (n !== 0) {
            fixed += ',';
          }
          fixed += n % 2 === 0 ? Number(coord) * w : Number(coord) * h;
        });
        area.coords = fixed;
      }
    });
  };

  //$(window).on('resize', fixImageMaps);
  //$(window).on('load', fixImageMaps);

  window.addEventListener('resize', fixImageMaps, true);
  window.addEventListener('load', fixImageMaps, true);

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
    componentLayoutExtended.render();
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  let renderComponentDocumentList = async (form) => {
    filterForm.ambit = form.ambit;
    filterForm.action = form.actions;
    filterForm.category = form.category;
    filterForm.process = form.process;
    filterForm.query = form.query;

    let documentListData = await servicesnoPeninsulares.archivesData(
      filterForm
    );

    let newDLArray = {
      isLoading: documentListData.isLoading,
      data: documentListData.newIndicators,
      renderAgain: (form) => renderComponentDocumentList(form),
      onlyRefresh: () => renderComponentListAgain(),
    };
    componentDocumentList.render(newDLArray, '#document-list-myp');
  };

  let renderComponentListAgain = async (form) => {
    let newDLArray = {
      renderAgain: (form) => renderComponentDocumentList(form),
      onlyRefresh: () => renderComponentListAgain(),
    };

    componentDocumentList.render(newDLArray, '#document-list-myp');
  };

  return {
    render: init,
  };
})();

export { componentnoPeninsulares };
